import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'


class BannerSection extends React.Component {


  render() {

    return (
      <section className="banner alngpad wallet-banner">  
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 left-side mb-3 mb-lg-0">
                <h1 className="main-heading"><span className='bluecolor'>ICO</span> Development Company</h1>
                <p className="pharagraph">Embrace the Futuristic Investment with our ICO Development today. </p>
                <p className='pharagraph mb-0'>Coinsclone, a successful ICO development company offers world-class Initial Coin Offering development services for newbie startups to become established entrepreneurs. We innovate new ideas and integrate them into your website to make an impact on your crypto business.</p>
                <a href="https://www.coinsclone.com/contact-us/" className="bluebtn mt-4">Grab a free demo</a>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 right-side">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/ico/ico-development-company-banner.webp"
                alt="ico development company"
                className='d-none d-md-block float-end'
                width={547}
              />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default BannerSection