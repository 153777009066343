import React from "react"

class DevService extends React.Component {

  render() {
    return (

      <section className="dapps ico pt-100 mb-0">
        <div className="container">
            <h2 className="heading-h2 text-center"><span className="heading-h3 bluecolor">ICO Development Services </span>
            Launch an Exponential Fundraising Dashboard</h2>
            <p className='text-center'>We offer numerous top-rated ICO development services to get the limelight in the cryptocurrency world.</p>
          <div className="row mb-3">
                    <div className='d-flex flex-wrap justify-content-center px-0'>
                        <div className='fea-card'>
                            <h3>ICO Consulting</h3>
                            <p className='pharagraph'>Our team empowers every newbie startup with tremendous <a href="https://www.coinsclone.com/ico-consulting-services/"> <b>ICO consulting services</b></a>. We assure to provide a myriad of guidance throughout the ICO development journey for hassle-free token distribution.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Crypto Wallet Development</h3>
                            <p className='pharagraph'>Our team specializes in developing seamless and secure crypto wallets. Cryptocurrency wallets are important to safeguard, store, and access the newly created crypto tokens. The crypto wallet we develop stands ahead in the crypto world with its infrastructure.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>ICO Token Creation</h3>
                            <p className='pharagraph'>We execute your vision in a prolific way for developing a flawless crypto token within your project budget. We develop all kinds of token types with exponential features, functionalities, and potentiality. Our developer team will be always ready to redesign your crypto token as per your business requirements.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Smart Contract Building</h3>
                            <p className='pharagraph'>Our technical team’s motto is to provide an inspiring outcome with our smart contract creation process. We also offer smart contract auditing services for governing your ICO website occasionally.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Legal Advisory Services</h3>
                            <p className='pharagraph'>We have a strong legal consulting expert team to offer you the best legal and regulatory guidance for the ICO website launch. Our legal experts explain the norms, regulations, rules, and other regulatory guidance for a smooth ICO development.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Marketing Services</h3>
                            <p className='pharagraph'>Our technical and sales team offers impressive web marketing solutions that seek the attention of crypto users. We help you to make your website reach a global audience who thrive on crypto investments. Our passion is to make you succeed in the crypto universe.</p>
                        </div>
                    </div>
          </div>
        </div>
      </section>
    )
  }
}

export default DevService
