import React from 'react'



class Whatis extends React.Component {


  render() {

    return (
      <section className="whatis pt-0">
        <div className='gray-bg'>
        <div className="container">
                <h2 className="heading-h2 text-center">Initiate Your <span className='bluecolor'>Fundraising Journey</span> with Our ICO Development Company</h2>
            <div className="text-center">
              <p className="pharagraph text-center">We, Coinsclone are well-versed in developing an exponential ICO landing page that catches the attention of crypto investors. Our developer team dedicates themselves to making a top-class initial coin offering platform with end-to-end features. We construct the ICO landing page bit by bit to ensure relevancy and quality.
              </p>
              <p className="pharagraph text-center mb-0">We assure you that ICO development will be a secret instinct for your business growth. Our developers team helps you to optimize the theme, security protocols, features, and display to make every pixel into profit. Shape your digital destiny with our ICO development services.
              </p>
            </div>
        </div>
        </div>
      </section>
    )
  }
}

export default Whatis