import React from "react"
import ButtonComponent from "../ButtonComponent/ButtonComponent"

class DevProcess extends React.Component {

  render() {
    return (
      <section className="process-of ico pt-100 mb-0">
          <div className="gray-bg">
            <div className="container">     
              <h2 className="heading-h2 text-center">
              <span className="heading-h3">What Makes Our <span className="bluecolor"> ICO Development Process</span> Stand Out Of The Box?</span>
              </h2>
              <p className="text-center">
              We focus on crafting an extraordinary ICO dashboard to catch the eyes of every investor to raise funds.
              </p>
              <div className="d-lg-flex add-one">
                <div className="square" >
                  <h3>Ideation</h3>
                  <p className="pharagraph">
                  Our developer team unleashes creativity to bring out unique ideas for creating the ICO project. At the initial stage, our expert team frames the outline for wallet integration, ICO token development, and ICO website design.
                  </p>
                </div>
                <div className="square">
                  <h3>WhitePaper Creation</h3>
                  <p className="pharagraph">
                  Our technical team helps you to frame a whitepaper where Tokenomics, objectives, pre-sale & post-sale details, and ICO website launch are included. Our technical team is mastered in providing the relevant information for crypto users in a simpler form.
                  </p>
                </div>
                <div className="square" >
                  <h3>Smart Contract Creation</h3>
                  <p className="pharagraph">
                  At Coinsclone, our team integrates exemplary and potential smart contracts to automate the functions. Also, we implement smart contract functions in crypto token creation to provide your users with seamless transactions.
                  </p>
                </div>
              </div>
              <div className="d-lg-flex add-two">
                <div className="square" >
                  <h3>ICO Token Development</h3>
                  <p className="pharagraph">
                  We transform your innovative ideas into reality with our cryptocurrency token development. Our technical team designs the token symbol, name, total supply, features, functionalities, and token standard. We also optimize the crypto token that suits your needs.
                  </p>
                </div>
                <div className="square" >
                  <h3>ICO Dashboard Designing</h3>
                  <p className="pharagraph">
                  Our team focuses on providing a seamless dashboard because that’s how your users see you. We integrate dashboards with user-friendly features, and robust security interfaces. Our UI/UX designers and technicians make your ICO website an unbeatable one.
                  </p>
                </div>
              </div>
              <div className="d-lg-flex add-three">
                <div className="square" >
                  <h3>Quality Analysis</h3>
                  <p className="pharagraph">
                  We focus on pioneering testing and quality-checking processes to offer a consistent ICO dashboard. Our testing team checks the working of the ICO platform to ensure it’s ability and technical errors. This helps your users to execute a seamless transaction.
                  </p>
                </div>
                <div className="square">
                  <h3>Token Market Launch</h3>
                  <p className="pharagraph">
                  After the testing process, our developer team helps you launch the ICO website and distribute the crypto tokens. In between the presale and post-token sale, our team checks the ICO website and updates the content accordingly.
                  </p>
                </div>
                <div className="square" >
                  <h3>ICO Promotion</h3>
                  <p className="pharagraph">
                  Our ICO marketers conduct PR campaigns, promotions, and strategies to promote the token sale. Bounty programs, airdrops, and other popular marketing techniques are also implemented to provide awareness of the token sale.
                  </p>
                </div>
              </div>
              <div className="mt-3 text-center">
                  <ButtonComponent />
              </div>
            </div>
          </div>
      </section>

    )
  }
}

export default DevProcess
