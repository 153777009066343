import React from 'react';
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import BannerCrypto from '../components/ico/Banner';
import Whatis from '../components/ico/Whatis';
import DevService from '../components/ico/DevServices';
import WhyChoose from '../components/ico/WhyChoose';
import FaqSection from '../components/ico/FaqSection';
import UserFriendly from '../components/Cryptoexchange/UserFriendly';
import Benificial from '../components/ico/Benificial';
import TechStack from '../components/ico/TechStack';
import DevProcess from '../components/ico/DevProcess';
import FeatureBenefits from '../components/ico/FeatureBenefits';





const IcoDev = () => (
  <section className="cryptoex">
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.coinsclone.com/ico-development-company/" />
        <title>Leading ICO Development Company - Coinsclone</title>
        <meta name="description" content="Coinsclone is a leading ICO development company, we provide custom solutions for ICO development services  like ICO website, ICO token creation at an affordable cost." />
        <meta name="keywords" content="ICO development company, ICO development services" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Leading ICO Development Company - Coinsclone" />
        <meta property="og:description" content="Coinsclone is a leading ICO development company, we provide custom solutions for ICO development services  like ICO website, ICO token creation at an affordable cost." />
        <meta property="og:url" content="https://www.coinsclone.com/ico-development-company/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/ico/ico-development-company-banner.webp" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Coinsclone" />
        <meta name="twitter:creator" content="@Coinsclone" />
        <meta name="twitter:description" content="Coinsclone is a leading ICO development company, we provide custom solutions for ICO development services  like ICO website, ICO token creation at an affordable cost." />
        <meta name="twitter:title" content="Leading ICO Development Company - Coinsclone" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/ico/ico-development-company-banner.webp" />
      </Helmet>
      
      <BannerCrypto />
      <div className="breadcrumb wallet-banner mb-0">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | ICO Development Company</span>
        </div>
      </div>
      <Whatis />
      <DevService />
      <DevProcess />
      <FeatureBenefits />
      <Benificial />
      <WhyChoose />
      <TechStack />
      <FaqSection />
      <UserFriendly />
    </Layout>
  </section>
)

export default IcoDev
