import React from "react"

class Benificial extends React.Component {

  render() {
    return (

      <section className="benifor ico pt-100 mb-0">
        <div className="gray-bg">
          <div className="container"> 
            <h2 className="heading-h2 text-center">
            <span className="heading-h3">Our <span className="bluecolor">Exponential Features </span>For ICO Development</span>
            </h2>
            <p className="text-center">
            Our developer team turns your dashboard imagination into reality with efficient and effective dashboard features.
            </p>
            <div className="d-lg-flex flex-wrap add-one">
              <div className="square" >
                <h3>Multiple Cryptocurrency Support</h3>
                <p className="pharagraph">
                Our ICO user landing page comes with supporting multiple cryptocurrency features to grab the attention of crypto investors. We work on the hustle to provide you and your user with a hassle-free website.
                </p>
              </div>
              <div className="square" >
                <h3>Seamless Payment Gateway</h3>
                <p className="pharagraph">
                Making every transaction on the ICO website safe and secure, we provide a crypto payment gateway for your crypto users. If users face any technical issue or anything else, they will contact the admin flawlessly.
                </p>
              </div>
              <div className="square" >
                <h3>Database Backup</h3>
                <p className="pharagraph">
                Restoring the transaction, withdrawal, and recent transaction data is essential for both the user and admin. Apparently, our developer team works on the back end to provide a data backup feature.
                </p>
              </div>
              <div className="square" >
                <h3>Security Protocols</h3>
                <p className="pharagraph">
                High-level security features like KYC/AML features, Two-factor authentication, jail login, and data encryption are implemented in the ICO dashboard. We also integrate biometric recognitions for users and the ICO website admin.
                </p>
              </div>
              <div className="square" >
                <h3>Bounty Programs</h3>
                <p className="pharagraph">
                Rewarding with free tokens is a strategic marketing ethic to seek the attention of crypto users and to bring awareness about the crypto project. We design separate spaces for conducting hassle-free bounty programs.
                </p>
              </div>
              <div className="square" >
                <h3>Flawless Wallet Integration</h3>
                <p className="pharagraph">
                With this feature, investors can transact, and store crypto tokens securely. Our technical team designs the end-to-end encrypted and secured crypto wallet for users. Also, we update the wallets occasionally to make them more advanced.
                </p>
              </div>
              <div className="square" >
                <h3>Real-Time Analytics</h3>
                <p className="pharagraph">
                Our developer team crafts the ICO admin dashboard with various analytical tools to monitor user activity. We customize the dashboard with this feature to prevent your ICO website from hacks, and irrelevant activities.
                </p>
              </div>
              <div className="square" >
                <h3>Customer Support</h3>
                <p className="pharagraph">
                To reach out to the admin easily, we offer 24/7 customer support on the ICO website. Users can solve their problems at any time without any interference. Our technicians design with utmost care to provide an easy navigating dashboard.
                </p>
              </div>
              <div className="square" >
                <h3>Legal and Regulatory Complaince</h3>
                <p className="pharagraph">
                Our team assures to offer relevant and potential tools for checking the legal norms for all geographical regions. Our high-end tools provide privacy policies, terms of services, and other regulatory details for administrators.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Benificial
